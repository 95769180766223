<template>
  <div class="divBox">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <!-- <el-form-item
        label="选择园区"
        :rules="[{ required: true, message: '请选择园区', trigger: 'blur' }]"
        prop="communityId"
      >
        <v-select
          :disabled="form.id != null"
          :options="communityList"
          v-model="form.communityId"
        />
      </el-form-item> -->
      <el-form-item
        label="社区达人类型"
        :rules="[
          { required: true, message: '请选择社区达人类型', trigger: 'blur' },
        ]"
        prop="talentType"
      >
        <v-select
          :width="width"
          :options="talentTypeOps"
          v-model="form.talentType"
          @change="changetalentType"
        />
      </el-form-item>

      <el-form-item
        label="社区达人姓名"
        :rules="[
          { required: true, message: '请输入社区达人姓名', trigger: 'blur' },
        ]"
        prop="talentUserId"
      >
        <v-select
          filterable
          :width="width"
          :options="talentUserOps"
          v-model="form.talentUserId"
          @change="changetalentUser"
        />
      </el-form-item>

      <el-form-item
        label="社区达人图片"
        :rules="[
          {
            required: false,
            message: '请上传社区达人封面图片',
            trigger: 'blur',
          },
        ]"
        prop="imageUrl"
      >
        <v-upload :limit="1" :imgUrls.sync="form.topicPicture" />
      </el-form-item>

      <el-form-item
        label="是否置顶"
        :rules="[
          { required: true, message: '请选择是否置顶', trigger: 'blur' },
        ]"
        prop="isTop"
      >
        <v-select :width="width" :options="isTopOps" v-model="form.isTop" />
      </el-form-item>

      <el-form-item
        v-if="form.isTop === 1"
        label="置顶排序"
        :rules="[
          {
            required: true,
            message: '请输入置顶排序值',
            trigger: ['blur', 'change'],
          },
          {
            message: '必须是数字格式',
            pattern: /^[0-9]+$/,
            trigger: 'blur',
          },
        ]"
        prop="isTopSort"
      >
        <v-input
          placeholder="请输入置顶排序值"
          v-model="form.isTopSort"
          min="1"
          :width="width"
          type="number"
        ></v-input>
      </el-form-item>

      <el-form-item
        label="风采标题"
        :rules="[
          { required: true, message: '请输入风采标题', trigger: 'blur' },
        ]"
        prop="topicTitle"
      >
        <v-input
          placeholder="请输入风采标题"
          :width="width"
          v-model="form.topicTitle"
        ></v-input>
      </el-form-item>

      <el-form-item
        label="风采内容"
        :rules="[
          { required: true, message: '请输入商品内容', trigger: 'blur' },
        ]"
        prop="topicContent"
      >
        <div id="editor">
          <v-ueditor :maxLength="20000" v-model="form.topicContent" />
        </div>
      </el-form-item>

      <el-form-item
        label="发布状态"
        :rules="[{ required: true, message: '请选择状态', trigger: 'blur' }]"
        prop="status"
      >
        <v-select :width="width" :options="statusOps" v-model="form.status" />
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import {
  addTopicURL,
  updateTopicURL,
  getTalentUserListURL,
  getInitInfoURL,
  getTopicInfoByIdURL,
} from "./api.js";
import { statusMap, setStatusOps, isTopMap, setIsTopOps } from "./map.js";

export default {
  name: "partnerForm",
  data() {
    return {
      width: 220,
      communityList: [],
      talentTypeOps: [],
      talentTypeMap: {},
      talentUserOps: [],
      talentUserMap: {},
      isTopSortOps: [],
      isTopOps: setIsTopOps(),
      isTopMap,
      statusOps: setStatusOps(),
      statusMap,
      submitConfig: {
        queryUrl: getTopicInfoByIdURL,
        submitUrl: "",
      },
      form: {
        id: null,
        topicType: 1,
        optUser: undefined, // 用户id
        communityId: undefined, // 园区id
        talentType: "", // 社区达人类型
        talentUserId: undefined,
        talentUserName: "", // 社区达人姓名
        isTop: 0, //是否置顶
        isTopSort: undefined, // 置顶排序
        topicTitle: "",
        topicContent: "",
        status: undefined,
        topicPicture: "",
        subPicture: "",
        // adminId: null,
      },
      communityId: null,
      onOff: true, // 是否进入编辑
    };
  },
  created() {
    this.getTalentType();
    this.getTalentUserList();

    const {
      id,
      talentType,
      talentUserId,
      talentUserName,
      topicPicture,
      topicTitle,
      topicContent,
    } = this.$route.query;
    // this.form.communityId = communityId;
    if (id !== undefined) {
      this.$nextTick(() => {
        this.$refs.formPanel.getData({ id });
      });
    }
    if (talentType) {
      (this.form.talentType = talentType),
        (this.form.talentUserId = talentUserId),
        (this.form.talentUserName = talentUserName),
        (this.form.topicPicture = topicPicture),
        (this.form.topicTitle = topicTitle),
        (this.form.topicContent = topicContent);
    }
    this.submitConfig.submitUrl =
      id !== undefined ? updateTopicURL : addTopicURL;
    this.onOff = id !== undefined;
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    changetalentType(value) {
      if (this.onOff) {
        return;
      }
      console.log(value, 89);
      this.form.talentUserId = "";
      this.getTalentUserList(value);
    },
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key] || this.form[key];
      });
      if (this.form.topicPicture) {
        this.form.subPicture = this.form.topicPicture;
      }
      this.$nextTick(() => {
        this.onOff = false;
      });
    },
    getTalentType() {
      let params = {
        tenantId: this.$store.state.app.userInfo.tenantId,
      };
      this.talentTypeOps = [];
      this.talentTypeMap = {};
      this.$axios.get(`${getInitInfoURL}`, { params: params }).then((res) => {
        if (res.code === 200 && res.data && res.data.talentTypeList) {
          res.data.talentTypeList.forEach((item) => {
            item.label = item.dictValue;
            item.value = item.dictValue;
            this.talentTypeOps.push(item);
            this.talentTypeMap[item.value] = item.label;
          });
        }
      });
    },
    getTalentUserList(talentType) {
      this.talentUserOps = [];
      this.talentUserMap = {};
      let params = {
        talentType: talentType,
      };
      this.$axios
        .get(`${getTalentUserListURL}`, { params: params })
        .then((res) => {
          if (res.code === 200 && res.data) {
            let ops = [];
            let map = {};
            res.data.forEach((item) => {
              item.label = item.userName;
              item.value = item.userId;
              ops.push(item);
              map[item.value] = item.label;
            });
            this.talentUserOps = ops;
            this.talentUserMap = map;
          }
        });
    },
    changetalentUser(val) {
      if (this.onOff) {
        return;
      }
      let result = {};
      if (val) {
        result = this.talentUserOps.filter((v) => v.userId === val)[0] || {};
      }
      // this.form.talentUserId = result.userId;
      this.form.talentUserName = result.userName;
    },
    submitBefore() {
      if (!this.form.topicPicture || this.form.topicPicture.length == 0) {
        this.$message.error("请上传商品封面图片");
        return;
      }

      if (!this.form.optUser) {
        this.form.optUser = this.$store.state.app.userInfo.userId;
      }

      return true;
    },
    submitSuccess() {
      return true;
    },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
}
</style>
